<template>
  <GlPageWrap
    class="pp-page__my-cases"
    hide-title-on-mobile
    title="My Cases"
  >
    <div class="pp-tabs">
      <div class="pp-tabs__left">
        <div
          class="pp-tabs__item"
          :class="{'pp-tabs__item--active': activeTab === 'active-cases'}"
          @click="handleActiveTab('active-cases')"
        >
          Active Cases
        </div>
        <div
          class="pp-tabs__item"
          :class="{'pp-tabs__item--active': activeTab === 'archive'}"
          @click="handleActiveTab('archive')"
        >
          Archived Cases
        </div>
      </div>
    </div>
    <ActiveCases
      v-if="activeTab === 'active-cases'"
      :active-tab="activeTab"
    />
    <ArchiveCases
      v-if="activeTab === 'archive'"
      :active-tab="activeTab"
    />
  </GlPageWrap>
</template>

<script>
// Components
import GlPageWrap from "@/components/layout/gl-page-wrap"
import ActiveCases from './components/ActiveCases'
import ArchiveCases from './components/ArchiveCases'

export default {
  components: {
    GlPageWrap,
    ActiveCases,
    ArchiveCases,
  },
  data() {
    return {
      search: '',
      activeTab: 'active-cases',
    }
  },
  mounted() {
    const tab = this.$route.query.tab || this.activeTab

    if (tab) {
      this.activeTab = tab
      this.$router.replace({ name: 'cases', query: { tab: this.activeTab } }).catch(err => err)
    }
  },
  methods: {
    handleActiveTab(name) {
      this.activeTab = name
      this.$router.replace({ name: 'cases', query: { tab: this.activeTab } }).catch(err => err)
    },
  },
}
</script>

<style>
.pp-page__my-cases {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
.pp-page__my-cases .gl-button {
  text-transform: none;
}

.pp-my-cases__loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

/* My cases tabs (need transfer to own component) */
.pp-tabs {
  display: flex;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-radius: 12px 12px 0 0;
}
.pp-tabs__left {
  flex: 1 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-auto-flow: column;
  max-width: 100%;
  overflow: auto;
}
.pp-tabs__left::-webkit-scrollbar {
  height: 4px;
}
.pp-tabs__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-grey-6-e);
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease;
}
.pp-tabs__item--active {
  font-weight: 600;
  color: var(--space-cadet);
  cursor: default;
}
.pp-tabs__item::after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: var(--soft-blue);
  position: absolute;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
}
.pp-tabs__item--active::after {
  width: 100%;
}

@media (max-width: 767px) {
  .pp-page__my-cases {
    padding-top: 56px;
    padding-bottom: 10px;
  }
  .pp-tabs {
    margin: 0 -10px 8px -10px;
    padding: 56px 16px 0 16px;
    border: none;
    border-radius: 0;
  }
  .pp-tabs__left {
    grid-template-columns: repeat(auto-fill, 160px);
  }
  .pp-tabs__item {
    min-width: 160px;
  }
}


/* My cases */
.pp-my-cases__desktop-view {
  display: block !important;
}
.pp-my-cases__tablet-view {
  display: none !important;
}
.pp-my-cases__component {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 14px 24px;
  background-color: var(--white);
  border-radius: 0 0 12px 12px;
  box-shadow: none;
  border: 1px solid var(--cotton-grey-f-5);
  border-top: none;
  position: relative;
}

/* My cases content */
.pp-my-cases__content {
  flex: 1 0 auto;
}
.pp-my-cases__content-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

/* My cases pagination */
.pp-my-cases__component .pp-pagination-table {
  padding: 14px 0 0 0;
}

/* My cases modals */
.pp-modal .gl-modal__container {
  padding: 24px;
}
.pp-modal .gl-modal__title {
  margin-bottom: 16px;
  text-transform: none !important;
}
.pp-modal .form-content-wrapper {
  margin-bottom: 48px;
}
.pp-modal .gl-form__button {
  width: calc(50% - 12px);
}
.pp-modal .gl-form__button.mr-2 {
  margin-right: 0 !important;
}
.pp-modal .gl-input__input {
  border-radius: 6px;
  font-size: 14px;
}
.pp-modal_text {
  font-size: 14px;
  line-height: 20px;
}
.pp-modal_text strong {
  font-weight: 600;
}
.pp-modal_text-note {
  margin-top: 12px;
  color: var(--dark-grey-6-e);
}
.pp-modal_text-note--bold {
  font-weight: 600;
  color: var(--space-cadet);
}

.pp-modal__rename-block + .pp-modal__rename-block {
  margin-top: 16px;
}
.pp-modal__rename-block-header {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey-6-e);
}
.pp-modal__rename .gl-modal__row {
  margin-bottom: 0 !important;
}
.pp-modal__rename-block-current {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  background-color: var(--cotton-grey-f-5);
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #C0C0C0;
  
}
.pp-modal__rename-block-current-el {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1399px) {
  .pp-my-cases__desktop-view {
    display: none !important;
  }
  .pp-my-cases__tablet-view {
    display: block !important;
  }

  .pp-my-cases__component {
    padding: 16px;
  }
  .pp-my-cases__content-wrap {
    gap: 16px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 1200px) {
  select, textarea, input {
    font-size: 14px !important;
  }
}
@media (max-width: 1023px) {
  .pp-my-cases__content-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .pp-my-cases__component {
    border-top: 1px solid var(--cotton-grey-f-5);
    border-radius: 12px;
  }
  .pp-my-cases__content-wrap {
    grid-template-columns: 1fr;
  }
}
</style>
